<template>
  <div class="base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">
          全部委托
        </h3>
        <p class="summary">
          相关委托已推送给相关分析师，分析师可登录P+APP查看处理，本页面仅做数据展示
        </p>
      </div>
    </div>
    <div class="pt20">
      <el-input
        placeholder="委托用户、电话以及分析师名称"
        v-model="queryData.keyword"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
    </div>
    <el-table class="body" :data="list">
      <el-table-column label="委托用户">
        <div slot-scope="scope" class="x aic">
          <img :src="scope.row.customerAvatarPath" class="logo" />
          <div style="height:50px" class="y3">
            <p class="fs16">{{ scope.row.customerName }}</p>
            <p class="color9">来自 {{ scope.row.visitSourceDesc }}</p>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="委托分析师">
        <template slot-scope="scope">
          <div class="y3">
            <p>{{ scope.row.analystName }}</p>
            <p>{{ scope.row.analystDesc }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="找房需求">
        <template slot-scope="scope">
          <div class="y3">
            <p>{{ scope.row.intentions && scope.row.intentions.join(",") }}</p>
            <p>{{ scope.row.appealDescription }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系电话">
        <template slot-scope="scope">
          <p>{{ scope.row.customerCellphone | formatPhone }}</p>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="200px">
        <template slot-scope="scope">
          <p>{{ scope.row.entrustTime }}</p>
        </template>
      </el-table-column>
      <el-table-column label="定制挑房" width="100px">
        <template slot-scope="scope">
          <p
            v-if="scope.row.hasRecommendReport"
            @click="toReport(scope.row)"
            class="blue pointer"
          >
            查看挑房
          </p>
          <p v-else>--</p>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page.sync="queryData.pageIndex"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :visible.sync="toggleIframeDialog"
      width="415px"
      title="查看挑房"
    >
      <iframe
        :src="iframeSrc"
        width="375px"
        height="500px"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { allEntrusts } from "@/api/appManage";

export default {
  data() {
    return {
      list: [],
      total: 0,
      status: {
        1: "已发布",
        2: "违规下架",
        3: "草稿"
      },
      pageSizes: [5, 10, 20, 30, 50],
      queryData: {
        pageIndex: 1,
        pageSize: 10,
        keyword: ""
      },
      iframeSrc: "",
      toggleIframeDialog: false
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  created() {
    this.allEntrusts();
  },
  methods: {
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.allEntrusts();
    },
    search() {
      this.queryData.pageIndex = 1;
      this.allEntrusts();
    },
    pageIndexChange(num) {
      this.queryData.pageIndex = num;
      this.allEntrusts();
    },
    async allEntrusts() {
      //获取列表
      const res = await allEntrusts(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    toReport(row) {
      const entrustId = row.id;
      const { userId, token } = this.userInfo;
      let url = `https://dealdata.fangmao.com/#/pages/pickRoom/index?siteId=1&entrustId=${entrustId}&userId=${userId}&token=${token}`;
      this.iframeSrc = url;
      this.toggleIframeDialog = true;
      // window.location.href = url;
    }
  }
};
</script>
<style lang="scss">
.dropdown-qrcode {
  padding: 30px 30px 20px 30px;
  .tips {
    line-height: 20px;
    text-align: center;
    color: $color9;
  }
  .img {
    width: 200px;
    height: 200px;
  }
}
</style>

<style scoped lang="scss">
.logo {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
}
</style>
